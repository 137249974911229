<template>
    <div class="content profile-content">
        <ProfileNavbar :title="$t('profile.surveys.title')"></ProfileNavbar>
        <div class="mob-menu-title">
            <router-link tag="a" to="/profile-parent"  class="mob-menu-back d-none">
                <img src="images/profile/arrow-back.svg">
            </router-link>
            {{ $t('profile.surveys.title') }}
        </div>
        <div class="clearfix">
            <div class="survey-title-mob">
                {{ $t('profile.modal-desc-2') }}
            </div>
            <div class="bg-blue">
                <div class="profile-cards survey-cards">
                    <div class="profile-card">
                        <img class="profile-card-survey" src="/images/profile/surveys-main.svg" alt="">
                        <div class="profile-card-title mb-2">{{ $t('profile.surveys.survey-name') }}</div>
                        <p class="profile-card-desc-1">{{ $t('profile.surveys.survey-desc-1') }}</p>
                        <p class="profile-card-desc-2">{{ $t('profile.surveys.survey-desc-2') }}</p>
                        <button class="btn btn-blue">{{ $t('profile.surveys.btn-take-survey') }}</button>
                    </div>
                    <!-- <div class="d-flex flex-row additional-block">
                        <img width="28" height="30" src="/images/profile/additional.svg" alt="">
                        <div class="ml-2">
                            {{ $t('profile.surveys.additional-text') }}
                        </div>
                    </div> -->
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import ProfileNavbar from "@/components/ProfileNavbar";
export default {
  name: 'Results',
  components: {
    ProfileNavbar
  },
}
</script>